import { render, staticRenderFns } from "./StaffMemberEditDialog.vue?vue&type=template&id=072c2578&scoped=true&"
import script from "./StaffMemberEditDialog.vue?vue&type=script&lang=js&"
export * from "./StaffMemberEditDialog.vue?vue&type=script&lang=js&"
import style0 from "./StaffMemberEditDialog.vue?vue&type=style&index=0&id=072c2578&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072c2578",
  null
  
)

export default component.exports