<template>
  <b-modal
    title="Etat d'une licence"
    centered
    :static="true"
    :lazy="true"
    v-model="modalShown"
    button-size="lg"
    @ok="handleOk"
    @close="close"
    @hidden="hide"
    @show="show"
    :ok-disabled="d_newState == null"
    ref="dialog"
  >
    <helper-note>
        <div>Attention, les modification d'information de licence ne valent pas que pour la compétition en cours !<br/>
        Toute information de licence est portée par la personne et sera donc identique chaque fois que la personne sera référencée dans l'application.<br/>
        Vous ne devriez donc indiquer la validité d'une licence qu'après l'avoir bien vérifiée sur l'<a href="https://intranet.ffvl.fr" target="_blank">intranet fédéral</a>.
        </div>
     </helper-note>

    <licensee-state
      :federalNumber="d_federal_number"
      :year="d_year"
      :peopleName="d_people_name"
      :showSummary="false"
      @change = "onChange"
    >
      <template #prepend="data">
        <h5>Licence {{ data.year }} de {{ data.people_name }} ({{ data.federal_number}})</h5>
      </template>
    </licensee-state>

  </b-modal>
</template>

<script>
//import { RST_COMPETITOR/*, RST_COMPETITION, LICENSEE*/ } from "@/LocalDB";
//import FormField from "@/components/Panels/FormField.vue";
//import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import HelperNote from "@/components/HelperNote";
import CompetitionsConfigurationHelper from "@/services/helpers/competitionsConfigurationHelper";
import LicenseeState from '../LicenseeState.vue';
//import { debounce } from "debounce";

export default {
  name: 'LicenseeEditDialog',
  components:{ /*FormField, VueBootstrapTypeahead,*/ HelperNote, LicenseeState },
  props: {
    value: { type:Boolean, default: false},
    callContext: { type: Object, required: false},
    federalNumber: { type:String, required: true},
    year : { type: Number, default: null},
    peopleName: {type: String, default: "" }
  },
  data(){
    return {
      d_call_context: this.callContext,
      d_federal_number: this.federalNumber,
      d_year: this.year,
      d_people_name: this.peopleName,

      modalShown: this.value,

      d_newState: null,
    };
  },
  methods:{
    handleOk(bvModalEvent){
      bvModalEvent.preventDefault();
      this.editLicence_Ok();
    },
    close(){
      this.$emit("close");
      this.$emit("input", false);
    },
    hide(){
      this.$emit("hide");
      this.$emit("input", false);
    },
    hideDialog(){
      this.$refs['dialog'].hide();
      this.$emit("input", false);
    },
    show(){
      this.d_newState = null;
      this.$emit("input", true);
    },

    onChange(newState){
      this.d_newState = newState;
    },

    async editLicence_Ok(){
      await this.$showSaver();
      this.hideDialog();
      setTimeout(this.completeSaving, 100);
    },
    async completeSaving(){
        var result = await CompetitionsConfigurationHelper.changeLicenceInfoAsync(this.d_newState.federal_number, this.d_newState.year, this.d_newState.isValid, this.d_newState.isCompetitor, this.d_newState.isMedicalCertificateValid);
        this.$hideSaver();
        this.toastAsyncSaved(result, "Informations de licence mises à jour");
        this.$emit("ok", { 
          context: this.d_call_context,
          federal_number: this.d_newState.federal_number, 
          year: this.d_newState.year, 
          isValid : this.d_newState.isValid, 
          isCompetitor: this.d_newState.isCompetitor, 
          isMedicalCertificateValid: this.d_newState.isMedicalCertificateValid 
        });
        this.saving = false;
    },
  },
  watch:{
    federalNumber: function (newVal){
      this.d_federal_number = newVal;
    },
    value: function(newVal)
    {
      this.modalShown = newVal;
    },
    year: function (newVal){
        this.d_year = newVal;
    },
    peopleName: function(newVal){
      this.d_people_name=newVal;
    },
    callContext: function(newVal){
      this.d_call_context = newVal;
    }
  }
}
</script>

<style scoped>
  .full-match { color: green; font-weight: bolder;}
  .half-match { color: goldenrod;}
  .no-match{ color: red;}
</style>
