<template>
  <div>
    <data-sync-state v-model="dataSynchronized" data="de membres du staff présents" @refresh="refresh"/>

    <b-row>
      <b-col>
        <b-check v-model="federalRollNumberVisible" switch>Afficher les n° de licence</b-check>
      </b-col>
      <b-col>
        <b-check v-model="capabilitiesVisible" switch>Afficher les compétences</b-check>
      </b-col>
    </b-row>

    <card-list-template>
        <template #header-commands-left>
            <b-button size="sm" v-if="!d_disabled" @click="create">{{ $t('pages.competition-admin.staff-members.add') }}</b-button>
        </template>
        <template #header-commands-right>
            <b-button size="sm" @click="refresh">{{ $t('pages.competition-admin.staff-members.refresh') }}</b-button>
        </template>

        <b-card v-for="member in selectedMembers" :key="member.id">
            <div style="display:flex; justify-content: space-between;">
                <div>
                    <span  v-if="member.isChiefJudge"><b-badge class="chief-judge">C</b-badge>&nbsp; &nbsp;</span><b>{{ member.firstname }} {{ member.lastname }}</b>&nbsp;<federal-number v-if="federalRollNumberVisible" :is-federal="competition.isFederal" v-model="member.LICENSEE" force-edit @edit="edit_licensee(member)"></federal-number>
                </div>
                <div>
                    <b-button size="sm" v-if="!d_disabled" @click="remove(member.staff_id)" variant="danger">Suppr.</b-button>
                </div>
            </div>
            <div v-if="capabilitiesVisible">
                <b-badge class="capability capability-judge" v-if="member.CAPABILITIES.isJudge">Juge</b-badge>
                <b-badge class="capability capability-judge capability-shadow" v-if="member.CAPABILITIES.isJudgeShadow">Juge ombre</b-badge>
                <b-badge class="capability capability-field-director" v-if="member.CAPABILITIES.isFieldDirector">Directeur de terrain</b-badge>
                <b-badge class="capability capability-field-director capability-shadow" v-if="member.CAPABILITIES.isFieldDirectorShadow">Directeur de terrain ombre</b-badge>
                <b-badge class="capability capability-scorer" v-if="member.CAPABILITIES.isScorer">Scoreur</b-badge>
                <b-badge class="capability capability-scorer capability-shadow" v-if="member.CAPABILITIES.isScorerShadow">Scoreur ombre</b-badge>
            </div>
            <div style="display:flex; justify-content:flex-end;">
              <b-button v-if="member.isChiefJudge && !d_disabled" @click="stafMembers_chiefJudgeStateChanged(member)" variant="outline-success">{{ $t('pages.competition-admin.staff-members.unset-as-chief-judge') }}</b-button>
              <b-button v-else-if="member.CAPABILITIES.isJudge && !d_disabled" @click="stafMembers_chiefJudgeStateChanged(member)" variant="outline-primary">{{ $t('pages.competition-admin.staff-members.set-as-chief-judge') }}</b-button>
            </div>

        </b-card>
    </card-list-template>

    <!-- Dialogue de traitement de l'état de validité de la license d'un pilote -->
    <licensee-edit-dialog
        v-model="displayLicenseeDialog"
        :federalNumber = 'editingMember.federal_number'
        :year = 'editingMember.year'
        :peopleName = editingMember.name
    ></licensee-edit-dialog>

    <staff-member-edit-dialog
        v-model="displayMemberEditDialog"
        :creation-mode="memberEditDialogForCreationMode"
        :title="displayMemberEditDialogTitle"
        :competition_id="competition_id"
        :search-suggestions="displayMemberEditDialogSuggestions"
        @staffAttached="refresh"
    >
    </staff-member-edit-dialog>

  </div>
</template>

<script>
import DataSyncState from '@/components/Utilities/DataSyncState.vue';
import CardListTemplate from '@/components/Lists/CardListTemplate.vue';
import FederalNumber from '@/components/Panels/FederalNumber.vue';
import LicenseeEditDialog from '@/components/Dialogs/LicenseeEditDialog.vue';
import StaffMemberEditDialog from '@/components/Dialogs/StaffMemberEditDialog.vue';

import {  STAFF, PEOPLE } from "@/LocalDB";

import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';

export default{
  model:{
    prop: 'competition',
  },
  props:{ 
    competition: { type:Object, required:true },
    disabled: { type:Boolean, default:false }
  },
  components: {  DataSyncState, CardListTemplate, FederalNumber, LicenseeEditDialog, StaffMemberEditDialog },
  computed:{
    competition_id(){ return this.d_competition.id; }
  },
  data(){
    return {
      VERBOSE: false,
      d_competition: this.competition,
      dataSynchronized: null ,

      selectedMembers: [],

      displayLicenseeDialog: false,
      editingMember: {
          federal_number: '',
          name: '',
          year: 0
      },
      displayMemberEditDialog: false,
      memberEditDialogForCreationMode: false,
      displayMemberEditDialogTitle: '',
      displayMemberEditDialogSuggestions: [],

      federalRollNumberVisible: true,
      capabilitiesVisible:true,

      d_disabled: this.disabled,
/*
      selected_member_id: null,


      showEditLicenseeDialog: false,
      selectedLicensee: '',
      selectedLicencePeopleName: '',
      selectedLicenceYear: null,
    
      staff: [],*/
    }
  },
  methods:{
    async stafMembers_chiefJudgeStateChanged(element)
    {
      if(this.disabled) return;
      try{
        await this.$showSaver()
        if(this.VERBOSE) console.log('chiefJudgeStateChanged', element);
        var success = await CompetitionsConfigurationHelper.toggleStaffMemberMainChiefJudgeStateAsync(this.d_competition.id, element.staff_id);
        this.toastAsyncSaved(success);
        if(success)
          element.isChiefJudge = !element.isChiefJudge;
        this.$hideSaver();
      }catch(error)
      {
        console.error('stafMembers_chiefJudgeStateChanged ERROR ', error);
        this.toastSaveFailure();
        await this.refresh();
      }
    },

    create(){
            this.memberEditDialogForCreationMode = true;
            this.displayMemberEditDialogTitle = this.$t("Ajout d'un membre");
            this.displayMemberEditDialogSuggestions = this.getEditDialogSuggestions();
            this.displayMemberEditDialog = true;
        },
        async remove(staff_id){
            var confirmed = await this.$bvModal.msgBoxConfirm(this.$t('pages.competition-admin.staff-members.remove-confirm'), {
                title: this.$t('dialogs.confirm.title'),
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                cancelVariant: 'secondary',
                okTitle: this.$t('dialogs.confirm.yes-button'),
                cancelTitle: this.$t('dialogs.confirm.no-button'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            });
            if(this.VERBOSE) console.log('confirmed', confirmed);
            if(confirmed){
                await this.showSaver();
                await CompetitionsConfigurationHelper.dettach_staff_Async(this.competition_id, staff_id);
                await this.refresh();
                this.hideSaver();
             }
        },
        edit_licensee(member){
            if(this.VERBOSE) console.log('edit_licensee', member.LICENSEE);
            this.editingMember = {
                federal_number: member.PEOPLE.federalNumber,
                name: member.PEOPLE.name,
                year: this.competition.year
            };
            this.displayLicenseeDialog = true;
        },
        getEditDialogSuggestions(){
            const staffs = STAFF.query()
                        .get()
                        .map(s => {
                            var mapped = s;
                            mapped.matches = '*';
                            if(s.HasAnyCapabilityForCompetitionTypeAndScopeAndLevels(this.competition.competitionType, this.competition.scope, this.competition.levels))
                                mapped.matches += '*';
                            if(this.competition.isFederal && s.isFederalValid)
                                mapped.matches += '*'
                            mapped.forSuggestion = mapped.matches + ' ' + s.name + (s.PEOPLE.isFederal ? ' - ' + s.PEOPLE.federalNumber : '');
                            return mapped;
                        });
            const staffsIds = staffs.map(s => s.id);
            const peoples = PEOPLE.query()
                        .where(p => !staffsIds.includes(p.id))
                        .get()
                        .map(p => {
                            var mapped = p;
                            mapped.matches = '';
                            mapped.forSuggestion = p.name + (p.isFederal ? ' - ' + p.federalNumber : '');
                            return mapped;
                        })
            const competitionStaffsId = this.selectedMembers.map(m => m.staff_id);

            return staffs.concat(peoples).filter(s => !competitionStaffsId.includes(s.id));
        },
    async refresh(){
      await this.$showRefresher();
      this.dataSynchronized = await CompetitionsConfigurationHelper.refreshStaffMembersAync(this.d_competition.id);

      var getResult = await CompetitionsConfigurationHelper.getStaffWithCapabilitiesAsync(this.competition_id);
      this.selectedMembers = getResult.data;
      this.dataSynchronized = getResult.isSync;
      
      if(this.VERBOSE) console.log('StaffmembersTab.refresh => revoir le chargement de la liste');
      this.$hideRefresher();
    }
  },
  async mounted(){
    if(this.VERBOSE) console.log('StaffmembersTab mounted');
    await this.refresh();
  },
  watch:{
    competition: function(newValue){
      this.d_competition = newValue;
    },
    disabled: function(newValue){
      this.d_disabled = newValue
    }
  }
}
</script>

<style>
    .badge.capability{
        margin-right: 10px;
        padding: 5px;
        border:solid 2px green;
        color: rgb(1, 68, 1);
    }

    .badge.capability-judge{
        background-color: rgb(244, 244, 158);
    }
    .badge.capability-field-director{
        background-color: rgb(122, 211, 243);
    }    
    .badge.capability-scorer{
        background-color: pink;
    }    
    .badge.capability.capability-shadow{
        border:solid 2px orange;
        color:rgb(78, 51, 1);
    }
    .badge.capability-shadow.capability-judge{
        background-color: rgb(247, 247, 199);
    }    
    .badge.capability-shadow.capability-field-director{
        background-color: rgb(176, 227, 246);
    }    
    .badge.capability-shadow.badge.capability-scorer{
        background-color: rgb(253, 229, 233);
    }   

    .badge.chief-judge{
      background-color: black;
      color:white;
      border: solid 2px gray;
    }
</style>