  <script>
  import { /*RST_STAFF,*/ RST_COMPETITION } from "@/LocalDB";
  import FormField from "@/components/Panels/FormField.vue";
  import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
  import HelperNote from "@/components/HelperNote";
  import CompetitionsConfigurationHelper from "@/services/helpers/competitionsConfigurationHelper";
  import LicenseeState from "@/components/LicenseeState.vue";
  
  export default {
    components:{ FormField, VueBootstrapTypeahead, HelperNote, LicenseeState },
    props: {
      value: { type:Boolean, default: false},
      title: { type: String, required: true},
      competition_id : { type: String, required: true},
      staff_id: { type: Object, default: null},
      creationMode: { type:Boolean, default: false},
      searchSuggestions: { type:Array, default: null},
    },
    data(){
      return {
        modalShown: this.value,
        editSearch: '',
        editSearchSuggestions: this.searchSuggestions,
  
        editingStaffId: this.staff_id,
        editingStaffFirstname : null, editingStaffFirstnameIsValid: null,
        editingStaffLastname: null,  editingStaffLastnameIsValid: null,
        editingStaffFederalNumber: null, editingStaffFederalNumberIsValid: null,
        editingStaffSexe: null, editingStaffSexeIsValid: null,
        editingStaffBirthdate: null, editingStaffBirthdateIsValid: null,
        editingStaffEmail: null, editingStaffEmailIsValid: null,
  
        isJudge: false, isJudgeShadow: false, isFieldDirector: false,
        isFieldDirectorShadow: false, isScorer: false, isScorerShadow: false,

        newStaff: false,
        creationHelperNoteVariant: 'info',
  
        d_updated_licensee_state: null,
      };
    },
    computed:{
      COMPETITION(){
        var comp = RST_COMPETITION.query().where("competition_id", this.competition_id).first();
        return comp;
      },
      minBirthdateYear(){
        return new Date().getFullYear() - 80;
      },
      maxBirthdateYear(){
        return new Date().getFullYear() - 5;
      },
      competitionIsOpen(){
        return this.COMPETITION.isOpen;
      },
    },
    methods:{
      checkFormValidity(){
        this.checkEditingStaffFirstnameValidity();
        this.checkEditingStaffLastnameValidity();
        this.checkEditingStaffFederalNumberValidity();
        this.checkEditingStaffSexeValidity();
        this.checkEditingStaffBirthdateValidity();
        this.checkEditingStaffEmailValidity();
  
        const valid = this.$refs.form.checkValidity();
        return valid;
      },
      handleOk(bvModalEvent){
        bvModalEvent.preventDefault();
        this.editStaffMember_Ok();
      },
      close(){
        this.$emit("close");
        this.$emit("input", false);
      },
      hide(){
        this.$emit("hide");
        this.$emit("input", false);
      },
      hideDialog(){
        this.$refs['dialog'].hide();
        this.$emit("input", false);
      },
      show(){
        this.reset(!this.creationMode && this.editingStaffId != null);
        this.$emit("input", true);
      },
      editStaffMemberSearch_Hit(selected){
          this.editingStaffId = selected;
          this.editingStaffFirstname = selected.PEOPLE.firstname;
          this.editingStaffLastname = selected.PEOPLE.lastname;
          this.editingStaffFederalNumber = selected.PEOPLE.federal_number;
      },
      async editStaffMember_Ok(){
        if(!this.checkFormValidity()) {
          return;
        }
        await this.$showSaver();
        this.hideDialog();
        setTimeout(this.completeSaving, 100);
      },
      async completeSaving(){
        var staffId = this.editingStaffId;
        var lic = this.d_updated_licensee_state;
        console.log('completeSaving', { creationMode: this.creationMode, newStaff: this.newStaff, staffId: staffId, lic: lic})
        if(this.creationMode){
          if(this.newStaff || staffId == null){
  
            staffId = CompetitionsConfigurationHelper.create_new_staff_from_scratch( // .create_new_individual_competitor_from_scratch(
                this.COMPETITION.competition_type,
                this.COMPETITION.scope,
                this.editingStaffFirstname,
                this.editingStaffLastname,
                this.editingStaffFederalNumber,
                this.editingStaffSexe,
                this.editingStaffBirthdate,
                this.editingStaffEmail,
                this.isJudge,
                this.isJudgeShadow,
                this.isFieldDirector,
                this.isFieldDirectorShadow,
                this.isScorer,
                this.isScorerShadow);
          }
    
          if(staffId != null)
          {
              await CompetitionsConfigurationHelper.attach_staff_async(this.competition_id, staffId.id);
              if(lic != null)
                await CompetitionsConfigurationHelper.changeLicenceInfoAsync(lic.federal_number, lic.year, lic.isValid, lic.isCompetitor, lic.isMedicalCertificateValid);
              this.$emit('staffAttached');
          } else {
              console.log('should not come here !');
              throw 'should not come here !';
          }
          this.reset();
          this.$hideSaver();
          this.toastSaved("Personne ajoutée");
          this.$emit("ok");
          this.saving = false;
        } else {
          var success = true;
          if(this.editingStaffFirstname != this.editingStaffId.firstname
            || this.editingStaffLastname != this.editingStaffId.lastname
            || this.editingStaffFederalNumber != this.editingStaffId.federal_number)
          {
            success = await CompetitionsConfigurationHelper.updateIndividualCompetitorDataAsync(this.editingStaffId.id, this.editingStaffFirstname, this.editingStaffLastname, this.editingStaffFederalNumber);
          }
  
          if(success && (lic != null))
              success = await CompetitionsConfigurationHelper.changeLicenceInfoAsync(lic.federal_number, lic.year, lic.isValid, lic.isCompetitor, lic.isMedicalCertificateValid);
          this.reset();
          this.$hideSaver();
          this.toastAsyncSaved(success, "Compétiteur modifié");
          this.$emit("ok");
          this.saving = false;
        }
      },
      newStaff_click(){
        this.newStaff = true;
        this.creationHelperNoteVariant = 'danger';
      },
      cancelNewStaff_click(){
        this.newStaff = false;
        this.editingStaffFirstnameIsValid = null;
        this.editingStaffLastnameIsValid = null;
        this.editingStaffFederalNumberIsValid = null;
        this.editingStaffSexeIsValid = null;
        this.editingStaffBirthdateIsValid = null;
        this.editingStaffEmailIsValid = null;
        this.creationHelperNoteVariant = 'info';
      },
      reset(exceptEditionFields = false){
        this.editSearch = '';
  
        if(!exceptEditionFields){
          this.editingStaffId = null;
          this.editingStaffFirstname = '';
          this.editingStaffLastname = '';
          this.editingStaffFederalNumber = '';
        }
        this.editingStaffFirstnameIsValid = null;
        this.editingStaffLastnameIsValid = null;
        this.editingStaffFederalNumberIsValid = null;
        this.editingStaffSexe = null;              this.editingStaffSexeIsValid = null;
        this.editingStaffBirthdate = null;         this.editingStaffBirthdateIsValid = null;
        this.editingStaffEmail = null;             this.editingStaffEmailIsValid = null;
  
        this.newStaff = false;
        this.creationHelperNoteVariant = 'info';
        this.d_updated_licensee_state = null;
      },
      checkEditingStaffFirstnameValidity(){
        this.editingStaffFirstnameIsValid = null;
        if((!this.creationMode || this.newStaff) && (this.editingStaffFirstname == null || this.editingStaffFirstname.trim() == ''))
          this.editingStaffFirstnameIsValid = false;
        return this.editingStaffFirstnameIsValid == null ? true : false;
      },
      checkEditingStaffLastnameValidity(){
        this.editingStaffLastnameIsValid = null;
        if((!this.creationMode || this.newStaff) && (this.editingStaffLastname == null || this.editingStaffLastname.trim() == ''))
          this.editingStaffLastnameIsValid = false;
        return this.editingStaffLastnameIsValid == null ? true : false;
      },
      checkEditingStaffFederalNumberValidity(){
        this.editingStaffFederalNumberIsValid = null;
        if((!this.creationMode || this.newStaff) && (this.editingStaffFederalNumber == null || this.editingStaffFederalNumber.trim() == '') && this.COMPETITION.COMPETITION_TYPE.isFederal && !this.COMPETITION.isOpen )
          this.editingStaffFederalNumberIsValid = false;
        return this.editingStaffFederalNumberIsValid == null ? true : false;
      },
      checkEditingStaffSexeValidity(){
        this.editingStaffSexeIsValid = null;
        if(this.creationMode && this.newStaff && (this.editingStaffSexe == null || this.editingStaffSexe.trim() == ''))
          this.editingStaffSexeIsValid = false;
        return this.editingStaffSexeIsValid == null ? true : false;
      },
      checkEditingStaffBirthdateValidity(){
        console.log('checkEditingCompetitorBirthdateValidity', this.editingStaffBirthdate);
        this.editingStaffBirthdateIsValid = null;
        if(this.editingStaffBirthdate != null){
          var y = this.editingStaffBirthdate.slice(0, 4);
          if(y < this.minBirthdateYear || y > this.maxBirthdateYear )
            this.editingStaffBirthdateIsValid = false;
        }
        return this.editingStaffBirthdateIsValid == null ? true : false;
      },
      checkEditingStaffEmailValidity(){
        this.editingStaffEmailIsValid = null;
        if(this.editingStaffEmail != null && this.editingStaffEmail != '' && !this.validateEmail(this.editingStaffEmail))
          this.editingStaffEmailIsValid = false;
        return this.editingStaffEmailIsValid == null ? true : false;
      },
      licenseeState_change(newState){
        this.d_updated_licensee_state = newState;
      },
    },
    watch:{
      searchSuggestions: function (newVal){
        this.editSearchSuggestions = (newVal == null) ? [] : newVal;
      },
      value: function(newVal)
      {
        this.modalShown = newVal;
      },
      staff_id(newVal)
      {
        this.reset();
          this.editingStaffId = newVal;
//          console.log('watch competitor_id', newVal);
          if(this.editingStaffId){ 
            this.editingStaffFirstname = newVal.firstname;
            this.editingStaffLastname = newVal.lastname;
            this.editingStaffFederalNumber = newVal.federal_number;
          }
      }
    }
  }
  </script>
  
  <template>
    <b-modal
      :title="title"
      centered
      :static="true"
      :lazy="true"
      v-model="modalShown"
      button-size="lg"
      :cancel-title="creationMode ? $t('dialogs.staff-member-edit.creation-mode-cancel') : $t('dialogs.staff-member-edit.edition-mode-cancel')"
      :ok-title="creationMode ? $t('dialogs.staff-member-edit.creation-mode-ok') : $t('dialogs.staff-member-edit.edition-mode-ok')"
      @ok="handleOk"
      @close="close"
      @hidden="hide"
      @show="show"
      ref="dialog"
      scrollable
      no-close-on-backdrop
    >
        <vue-bootstrap-typeahead
            v-show = 'creationMode && !newStaff'
            v-model="editSearch"
            :data="editSearchSuggestions"
            :minMatchingChars="2"
            :serializer="s => s.forSuggestion"
            :placeholder="$t('dialogs.staff-member-edit.search-placeholder')"
            @hit="editStaffMemberSearch_Hit"
            :prepend="$t('dialogs.staff-member-edit.search')"
            ref="individualCompetitorSearchArea"
        >
          <template slot="suggestion" slot-scope="{ data, htmlText }">
            <span 
              :class="{'full-match': data.matches == '***', 'almost-full-match': data.matches == '**', 'half-match': data.matches=='*', 'no-match': data.matches == ''}"
              v-html="htmlText"
            ></span>
          </template>
        </vue-bootstrap-typeahead>
  
        <helper-note v-show="creationMode" :variant="creationHelperNoteVariant">

            <div v-html="$t('dialogs.staff-member-edit.info')"></div>
            <b-button variant="secondary" size="sm" @click="newStaff_click" :disabled="newStaff">{{ $t('dialogs.staff-member-edit.new') }}</b-button>
            <b-button variant="secondary" size="sm" @click="cancelNewStaff_click" :disabled="!newStaff">{{ $t('dialogs.staff-member-edit.cancel-new') }}</b-button>
        </helper-note>

        <b-form ref="form" @submit.stop.prevent="editIndividualCompetitor_Ok">
  
        <form-field :caption="$t('models.PEOPLE.firstname')" required>
          <b-form-input type="text"
            v-model="editingStaffFirstname"
            required
            :disabled="creationMode && !newStaff"
            :state="editingStaffFirstnameIsValid"
            @blur="checkEditingStaffFirstnameValidity"
            @input="editingStaffFirstnameIsValid = null"
            :invalidFeedBack="$t('models.PEOPLE.__requirements.firstname')"
          />
        </form-field>
        <form-field :caption="$t('models.PEOPLE.lastname')" required>
          <b-form-input type="text"
            v-model="editingStaffLastname"
            required
            :disabled="creationMode && !newStaff"
            :state="editingStaffLastnameIsValid"
            @blur="checkEditingStaffLastnameValidity"
            @input="editingStaffLastnameIsValid = null"
            :invalidFeedBack="$t('models.PEOPLE.__requirements.lastname')"
          />
        </form-field>
        <form-field :label="$t('models.PEOPLE.federal-number')" :required="COMPETITION.COMPETITION_TYPE.isFederal && !COMPETITION.isOpen">
          <b-form-input type="text"
            v-model="editingStaffFederalNumber"
            :disabled="creationMode && !newStaff"
            :required="COMPETITION.COMPETITION_TYPE.isFederal && !COMPETITION.isOpen"
            :state="editingStaffFederalNumberIsValid"
            @blur="checkEditingStaffFederalNumberValidity"
            @input="editingStaffFederalNumberIsValid = null"
          />
        </form-field>
  
        <licensee-state 
          v-show="editingStaffFederalNumber != null && editingStaffFederalNumber.length == 8"
          :federalNumber="editingStaffFederalNumber"
          :showSummary="false"
          @change="licenseeState_change"
        >
          <template #prepend="data">
            <b>Etat de la licence {{ data.year }} de {{ editingStaffFirstname || editingStaffLastname || 'cette personne' }}</b>
          </template>
        </licensee-state>
  
        <form-field :label="$t('models.PEOPLE.gender')" required v-show="creationMode && newStaff">
          <b-form-radio-group
            v-model="editingStaffSexe"
            :required="creationMode && newStaff"
            :state="editingStaffSexeIsValid"
            @blur="checkEditingStaffSexeValidity"
            @input="editingStaffSexeIsValid = null"
          >
            <b-form-radio value="M" :state="editingStaffSexeIsValid">{{ $t('meta.genders.male')}}</b-form-radio>
            <b-form-radio value="F" :state="editingStaffSexeIsValid">{{ $t('meta.genders.female')}}</b-form-radio>
          </b-form-radio-group>
        </form-field>
  
        <form-field :label="$t('models.PEOPLE.birthdate')" v-show="creationMode && newStaff">
          <!--<b-form-datepicker v-model="editingStaffBirthdate" />-->
          <b-form-input type="date"
            v-model="editingStaffBirthdate"
            :min="minBirthdateYear + '-01-01'"
            :max="maxBirthdateYear + '-12-31'"
            :state="editingStaffBirthdateIsValid"
            @blur="checkEditingStaffBirthdateValidity"
            @input="editingStaffBirthdateIsValid = null"
          />
        </form-field>
        <form-field :label="$t('models.PEOPLE.email')" v-show="creationMode && newStaff">
          <b-form-input type="email"
            v-model="editingStaffEmail"
            :state="editingStaffEmailIsValid"
            @blur="checkEditingStaffEmailValidity"
            @input="editingStaffEmailIsValid = null"
            :locale="$t('language-selector.date-picker-locale')"
           />
        </form-field>
      </b-form>
    </b-modal>
  </template>
  
  <style scoped>
    .full-match { color: green; font-weight: bolder;}
    .almost-full-match { color: green;}
    .half-match { color: goldenrod;}
    .no-match{ color: red;}
  </style>
  